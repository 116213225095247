<template>
  <section id="block_4" class="block_4">
    <div class="wrap">
      <div class="content">
        <h4>{{ text[activeLang].header }}</h4>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="slidesPerView !== 2"
        :activeLang="activeLang"
        :link="'/download/3.zip'"
      ></app_button>
    </div>
    <app_slider
      :className="'slider_block4'"
      :spaceBetween="30"
      :slidesPerView="slidesPerView"
      :initialSlide="1"
      :slidersFileNameFolder="'block_4_pic'"
      :slidersFileName="sliders"
      :slidersFileNameExtension="'jpg'"
      :autoplayDelay="2000"
      :navigation="false"
      :pagination="false"
    ></app_slider>
    <app_slider
      :className="'slider_block4'"
      :spaceBetween="30"
      :slidesPerView="slidesPerView"
      :initialSlide="1"
      :slidersFileNameFolder="'block_4_pic'"
      :slidersFileName="sliders1"
      :slidersFileNameExtension="'jpg'"
      :autoplayDelay="2000"
      :navigation="false"
      :pagination="false"
    ></app_slider>
    <app_button
      class="wrap"
      v-if="slidesPerView === 2"
      :activeLang="activeLang"
      :link="'/download/3.zip'"
    ></app_button>
  </section>
</template>

<script>
import app_button from '@/components/app_button';
import app_slider from '@/components/app_slider';

export default {
  name: 'app_block_4',
  data() {
    return {
      sliders: ['Pic_1', 'Pic_2', 'Pic_3', 'Pic_4', 'Pic_5', 'Pic_6'],
      sliders1: ['Pic_7', 'Pic_8', 'Pic_9', 'Pic_10', 'Pic_11', 'Pic_12'],
      text: {
        ru: {
          header: 'Портреты артистов',
          text: 'Уникальная авторская отрисовка, включающая в себя применение фирменных цветов бренда и комбинации графических ключей. Используются на сайте, в анонсах и рекламе как самих артистов, так и фестиваля в целом.',
        },
        en: {
          header: 'Portraits of artists',
          text: 'Unique author\'s rendering, which includes the use of brand colors and combinations of graphic keys. They are used on the website, in announcements and advertisements of both the artists themselves and the festival in general.',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button, app_slider },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      if (document.documentElement.clientWidth >= 1920) return 6;
      if (document.documentElement.clientWidth >= 1440) return 5;
      if (document.documentElement.clientWidth >= 1024) return 4;
      if (document.documentElement.clientWidth >= 768) return 3;
      if (document.documentElement.clientWidth <= 576) return 2;
    },
  },
};
</script>

<style scoped></style>
