<template>
  <nav id="menu">
    <div class="menu__content">
      <a href="https://guideline.beon1x.com/"><div class="menu__logo"></div></a>
      <div class="menu__lang">
        <span
          v-for="(ln, i) in lang"
          :key="i"
          :class="{ active: activeLang === ln }"
          @click="$emit('changeActiveLang', ln)"
          >{{ ln }}</span
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'app_menu',
  props: {
    lang: Array,
    activeLang: String,
  },
  emits: ['changeActiveLang'],
};
</script>

<style scoped></style>
