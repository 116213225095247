<template>
  <section id="block_3" class="block_3">
    <div class="wrap">
      <div class="content">
        <h3>{{ text[activeLang].header }}</h3>
        <h4>{{ text[activeLang].subHeader }}</h4>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="slidesPerView"
        :activeLang="activeLang"
        :link="'/download/2.zip'"
      ></app_button>
      <img src="@/assets/img/img_block_3_1920.png" alt="keys" />
    </div>
    <template v-if="slidesPerView">
      <app_slider
        :className="'slider_block3'"
        :spaceBetween="30"
        :slidesPerView="slidesPerView"
        :initialSlide="2"
        :slidersFileNameFolder="'block_3_pic'"
        :slidersFileName="sliders"
        :slidersFileNameExtension="'png'"
        :autoplayDelay="2000"
        :navigation="true"
        :pagination="true"
      ></app_slider>
    </template>
    <template v-else>
      <div class="wrap">
        <img
          v-for="(s, i) in sliders"
          :key="i"
          :src="'/img/block_3_pic/' + s + '.png'"
          alt="keys"
        />
        <app_button
          style="margin-top: 30px"
          :activeLang="activeLang"
          :link="'/download/2.zip'"
        ></app_button>
      </div>
    </template>
  </section>
</template>

<script>
import app_button from '@/components/app_button';
import app_slider from '@/components/app_slider';

export default {
  name: 'app_block_3',
  data() {
    return {
      sliders: [1, 2, 3, 4],
      text: {
        ru: {
          header: 'Использование графических ключей',
          subHeader: 'Диджитал носители',
          text: 'Гибкая система комбинации ключей и вариативность символов позволяет развивать графику, делать её разнообразной и при этом оставаться в рамках фирменного стиля.',
        },
        en: {
          header: 'Using Graphic keys',
          subHeader: '',
          text: 'A flexible system of key combinations and character variability allows you to develop graphics, make it diverse and at the same time remain within the corporate identity.',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button, app_slider },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      if (document.documentElement.clientWidth >= 1920) return 1.1932;
      if (document.documentElement.clientWidth >= 1440) return 1.28;
      if (document.documentElement.clientWidth >= 1024) return 1.095;
      if (document.documentElement.clientWidth >= 768) return false;
    },
  },
};
</script>

<style scoped></style>
