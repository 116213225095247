<template>
  <div class="wrap_btn">
    <a :href="link" class="btn">{{ btn[activeLang] }}</a>
  </div>
</template>

<script>
export default {
  name: 'app_button',
  data() {
    return {
      btn: {
        ru: 'Скачать',
        en: 'Download',
      },
    };
  },
  props: ['activeLang', 'link'],
};
</script>

<style scoped></style>
