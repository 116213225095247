<template>
  <section id="block_8" class="block_8">
    <div class="wrap">
      <div class="content">
        <h3>{{ text[activeLang].header }}</h3>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="!slidesPerView"
        :activeLang="activeLang"
        :link="'/download/7.zip'"
      ></app_button>
    </div>
    <div class="video">
      <video playsinline autoplay muted loop poster="/img/block_8_pic/1.png">
        <source
          src="/video/beonix_v6_1080x1920_v2_4.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <video playsinline autoplay muted loop poster="/img/block_8_pic/2.png">
        <source
          src="/video/festival_announcement.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <video playsinline autoplay muted loop poster="/img/block_8_pic/3.png">
        <source
          src="/video/Boris_v3_2.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <video playsinline autoplay muted loop poster="/img/block_8_pic/4.png">
        <source
          src="/video/RYKA_Fullv5.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <app_button
        class="wrap"
        style="margin-top: 30px; margin-bottom: 100px"
        v-if="slidesPerView"
        :activeLang="activeLang"
        :link="'/download/7.zip'"
      ></app_button>
      <video playsinline autoplay muted loop poster="/img/block_8_pic/5.png">
        <source
          src="/video/tree.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <p>{{ text[activeLang].textP }}</p>
    </div>
  </section>
</template>

<script>
import app_button from '@/components/app_button';

export default {
  name: 'app_block_8',
  data() {
    return {
      text: {
        ru: {
          header: 'Видео анонсы',
          text: 'Для продвижения бренда, в рамках гайдлайнов, создаются видеоматериалы с использованием графических ключей, портретов артистов и 3D изображений.',
          textP: 'Продолжение следует...',
        },
        en: {
          header: 'Video announcements',
          text: 'To promote the brand, within the guidelines we create video materials using graphic keys, portraits of artists and 3D images.',
          textP: 'To be continued...',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      return document.documentElement.clientWidth <= 576;
    },
  },
};
</script>

<style scoped></style>
