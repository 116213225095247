<template>
  <swiper
    :navigation="navigation"
    :pagination="pagination"
    :modules="modules"
    :slidesPerView="slidesPerView"
    :centeredSlides="true"
    :spaceBetween="spaceBetween"
    :grabCursor="true"
    :initialSlide="initialSlide"
    :loop="true"
    :class="className"
    :autoplay="{
      delay: autoplayDelay,
    }"
    :speed="1000"
  >
    <swiper-slide v-for="(s, i) in slidersFileName" :key="i"
      ><img
        :src="
          '/img/' +
          slidersFileNameFolder +
          '/' +
          s +
          '.' +
          slidersFileNameExtension
        "
        alt=""
    /></swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';

// import required modules
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
  Grid,
} from 'swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: [
    'className',
    'spaceBetween',
    'slidesPerView',
    'initialSlide',
    'slidersFileNameFolder',
    'slidersFileName',
    'autoplayDelay',
    'slidersFileNameExtension',
    'navigation',
    'pagination',
    'grid',
  ],
  setup() {
    return {
      modules: [Navigation, Pagination, Mousewheel, Keyboard, Autoplay, Grid],
    };
  },
};
</script>
