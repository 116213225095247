<template>
  <section id="block_5" class="block_5">
    <div class="wrap">
      <div class="content">
        <h3>{{ text[activeLang].header }}</h3>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="slidesPerView !== 2"
        :activeLang="activeLang"
        :link="'/download/4.zip'"
      ></app_button>
    </div>
    <app_slider
      :className="'slider_block5'"
      :spaceBetween="30"
      :slidesPerView="slidesPerView"
      :initialSlide="1"
      :slidersFileNameFolder="'block_5_pic'"
      :slidersFileName="sliders"
      :slidersFileNameExtension="'png'"
      :autoplayDelay="2000"
      :navigation="false"
      :pagination="false"
    ></app_slider>

    <app_button
      class="wrap"
      style="margin-top: 30px"
      v-if="slidesPerView === 2"
      :activeLang="activeLang"
      :link="'/download/4.zip'"
    ></app_button>
  </section>
</template>

<script>
import app_button from '@/components/app_button';
import app_slider from '@/components/app_slider';

export default {
  name: 'app_block_5',
  data() {
    return {
      sliders: ['pic_1', 'pic_2', 'pic_3', 'pic_4', 'pic_5', 'pic_6'],
      text: {
        ru: {
          header: 'Анонс артистов',
          text: 'Анимированное представление артистов с использованием фиксированной верстки, которая, как и портреты артистов, является графическим ключом.',
        },
        en: {
          header: 'Announcement of artists',
          text: 'An animated representation of artists using a fixed layout, which, like portraits of artists, is a graphic key.',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button, app_slider },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      if (document.documentElement.clientWidth >= 1920) return 4;
      if (document.documentElement.clientWidth >= 1440) return 4;
      if (document.documentElement.clientWidth >= 1024) return 3;
      if (document.documentElement.clientWidth >= 768) return 2.5;
      if (document.documentElement.clientWidth <= 576) return 2;
    },
  },
};
</script>

<style scoped></style>
