<template>
  <section id="block_2" class="block_2">
    <div class="wrap">
      <app_button
        :activeLang="activeLang"
        :link="'/download/1.zip'"
      ></app_button>
    </div>
    <app_slider
      :className="'slider_block2'"
      :spaceBetween="20"
      :slidesPerView="slidesPerView"
      :initialSlide="3"
      :slidersFileNameFolder="'block_2_pic'"
      :slidersFileName="sliders"
      :slidersFileNameExtension="'png'"
      :autoplayDelay="2000"
      :navigation="false"
      :pagination="false"
    ></app_slider>
  </section>
</template>

<script>
import app_button from '@/components/app_button';
import app_slider from '@/components/app_slider';

export default {
  name: 'app_block_2',
  data() {
    return {
      sliders: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  props: ['activeLang'],
  components: { app_button, app_slider },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      if (document.documentElement.clientWidth >= 1920) return 6;
      if (document.documentElement.clientWidth >= 1440) return 5;
      if (document.documentElement.clientWidth >= 1024) return 4;
      if (document.documentElement.clientWidth >= 768) return 3;
      if (document.documentElement.clientWidth <= 576) return 2;
    },
  },
};
</script>

<style scoped></style>
