<template>
  <section id="block_7" class="block_7">
    <div class="wrap">
      <div class="content">
        <h4>{{ text[activeLang].header }}</h4>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="!slidesPerView"
        :activeLang="activeLang"
        :link="'/download/6.zip'"
      ></app_button>
    </div>
    <div class="icons">
      <img
        v-for="(icon, i) in icons"
        :key="i"
        :src="'/img/block_7_pic/' + icon + '.png'"
        alt=""
      />
    </div>
    <app_button
      class="wrap"
      style="margin-top: 30px"
      v-if="slidesPerView"
      :activeLang="activeLang"
      :link="'/download/6.zip'"
    ></app_button>
    <div class="imgHead"></div>
  </section>
</template>

<script>
import app_button from '@/components/app_button';

export default {
  name: 'app_block_7',
  data() {
    return {
      icons: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      text: {
        ru: {
          header: 'Иконки',
          text: 'Был разработан яркий пак иконок. Иконки могут быть использованы как отдельные самостоятельные изображения, а также размещаться на любых носителях.',
        },
        en: {
          header: 'Icons',
          text: 'A bright pack of icons has been developed. Icons can be used as separate independent images, as well as placed on any media.',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      return document.documentElement.clientWidth <= 576;
    },
  },
};
</script>

<style scoped></style>
