<template>
  <div class="bg_animation">
    <div class="ball ball1"></div>
    <div class="ball ball2"></div>
  </div>
  <app_menu
    :lang="lang"
    :activeLang="activeLang"
    @change-active-lang="changeActiveLang"
  ></app_menu>
  <back-to-top v-if="showBacToTop" text="">
    <img src="/img/backToTop.svg" alt="" />
  </back-to-top>
  <app_block_1 :activeLang="activeLang"></app_block_1>
  <app_block_2 :activeLang="activeLang"></app_block_2>
  <app_block_3 :activeLang="activeLang"></app_block_3>
  <app_block_4 :activeLang="activeLang"></app_block_4>
  <app_block_5 :activeLang="activeLang"></app_block_5>
  <app_block_6 :activeLang="activeLang"></app_block_6>
  <app_block_7 :activeLang="activeLang"></app_block_7>
  <app_block_8 :activeLang="activeLang"></app_block_8>
</template>

<script>
import { gsap } from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/CSSPlugin';
gsap.registerPlugin(CSSPlugin);
import BackToTop from 'vue-backtotop';
import app_menu from '@/components/app_menu';
import app_block_1 from '@/components/app_block_1';
import app_block_2 from '@/components/app_block_2';
import app_block_3 from '@/components/app_block_3';
import app_block_4 from '@/components/app_block_4';
import app_block_5 from '@/components/app_block_5';
import app_block_6 from '@/components/app_block_6';
import app_block_7 from '@/components/app_block_7';
import app_block_8 from '@/components/app_block_8';

export default {
  name: 'App',
  data() {
    return {
      lang: ['ru', 'en'],
      activeLang: 'ru',
    };
  },
  methods: {
    changeActiveLang(ln) {
      this.activeLang = ln;
    },
  },
  components: {
    app_menu,
    BackToTop,
    app_block_1,
    app_block_2,
    app_block_3,
    app_block_4,
    app_block_5,
    app_block_6,
    app_block_7,
    app_block_8,
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    showBacToTop() {
      return document.documentElement.clientWidth >= 576;
    },
  },
  mounted() {
    // анимация темного фона
    let tlBall = gsap.timeline({ repeat: -1, delay: 3 });
    tlBall.to('.ball1', {
      y: '100vh',
      x: '-100vw',
      duration: 10,
    });
    tlBall.to('.ball1', {
      y: 0,
      x: 0,
      duration: 10,
    });
    let tlBall2 = gsap.timeline({ repeat: -1, delay: 3 });
    tlBall2.to('.ball2', {
      y: '-100vh',
      x: '100vw',
      duration: 10,
    });
    tlBall2.to('.ball2', {
      y: 0,
      x: 0,
      duration: 10,
    });
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variable';
#app {
  background-color: $blackBg;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}
</style>
