<template>
  <section id="block_6" class="block_6">
    <div class="wrap">
      <div class="content">
        <h3>{{ text[activeLang].header }}</h3>
        <p>{{ text[activeLang].text }}</p>
      </div>
      <app_button
        v-if="!slidesPerView"
        :activeLang="activeLang"
        :link="'/download/5.zip'"
      ></app_button>
    </div>
    <img src="/img/block_6_pic/1.png" alt="banner" />
    <img src="/img/block_6_pic/2.png" alt="banner" />
    <div class="wrap">
      <img src="/img/block_6_pic/3.png" alt="banner" />
      <video playsinline autoplay muted loop poster="/img/block_6_pic/4.png">
        <source
          src="/video/Beonix_ball.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
      </video>
      <img src="/img/block_6_pic/b.png" alt="banner" />
    </div>
    <app_button
      class="wrap"
      style="margin-top: 30px"
      v-if="slidesPerView"
      :activeLang="activeLang"
      :link="'/download/5.zip'"
    ></app_button>
  </section>
</template>

<script>
import app_button from '@/components/app_button';

export default {
  name: 'app_block_6',
  data() {
    return {
      text: {
        ru: {
          header: '3D',
          text: 'Были созданы 3D-изображения, которые в совокупности с основными графическими ключами составляют гармоничные, яркие и запоминающиеся композиции.',
        },
        en: {
          header: '3D',
          text: '3D images were created, which together with the main graphic keys make harmonious, bright and memorable compositions.',
        },
      },
    };
  },
  props: ['activeLang'],
  components: { app_button },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    slidesPerView() {
      return document.documentElement.clientWidth <= 576;
    },
  },
};
</script>

<style scoped></style>
