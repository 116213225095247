<template>
  <section id="block_1" class="block_1 pb60">
    <div class="wrap">
      <div class="block_1__logo mb60"></div>
      <p class="p1 mb60" v-html="text[activeLang].p1"></p>
      <h2 class="head1">{{ text[activeLang].head1 }}</h2>
      <h3>{{ text[activeLang].h3_1 }}</h3>
      <p v-html="text[activeLang].p_1"></p>
      <h3>{{ text[activeLang].h3_2 }}</h3>
      <p v-html="text[activeLang].p_2"></p>
      <h3>{{ text[activeLang].h3_3 }}</h3>
      <p v-html="text[activeLang].p_3"></p>
      <h3>{{ text[activeLang].h3_4 }}</h3>
      <p v-html="text[activeLang].p_4"></p>
    </div>
    <div class="block_1__man"></div>
  </section>
</template>

<script>
export default {
  name: 'app_block_1',
  data() {
    return {
      text: {
        ru: {
          p1: 'Эта айдентика — впечатление о будущем, которое уже наступило.<br> О чем-то футуристически далеком, но уже ставшем реальностью благодаря человеку.<br> О рукотворном слиянии технологий будущего с вечными законами природы.<br> О найденной гармонии.',
          head1: 'Графические ключи фирменного стиля',
          h3_1: 'Человек',
          h3_2: 'Линия',
          h3_3: 'Градиент',
          h3_4: 'О - композиция',
          p_1: 'Человек — ключевой образ, в котором сходятся все формы философии бренда — искусство, наука, эволюция технологий. Он творец и венец творения, автор прекрасной музыки и бессмертных произведений, двигатель высоких технологий. Человек — идеальная пропорция, описанная еще Леонардо да Винчи. «Число Бога», заложенное в нашей концепции — символ гармонии Вселенной. Образ идеально ложится на концепцию бионики о поиске абсолютной гармонии между наукой, новыми технологиями, искусством, духовностью и культурой. Графически он подан технологично и футуристично, его удобно раскладывать по направлениям фестиваля и интерпретировать под разные задачи, например: человек — это диджей на афише, это живопись стрит-арт художника на улицах Кипра, навеянная античным искусством и серия иллюстраций, основным мотивом которых тоже стал человек — тот, кто создает музыку и тот, кто ее слушает, участник фестиваля и наш гость.',
          p_2: 'Визуальная метафора нейросети и нейронных связей в мозге, квантовые и морские волны...<br>Графически элемент вытекает из стиля логотипа, сотканного из единой непрерывной линии такое визуальное решение очень удобно в работе, поддерживает общую концепцию.',
          p_3: 'Не зря в кинопроизводстве так часто используется мадженто-циановая схема света. Камерный, очень гармоничный градиент задает космически-футуристическую атмосферу и выигрышно смотрится как на digital носителях, так и в офлайн (мерч).',
          p_4: 'Круг, а также все существующие символы и системы верстки, которые в него вписываются. Идеальная гармоничная фигура - быстро считывается, с ней легко работать, придумывать под нее сюжеты и узнаваемо вписывать практически любую графику.',
        },
        en: {
          p1: 'This identity is an impression of the future that has already come.<br>About something futuristically distant, but has already become a reality because of humanity.<br>About the man-made fusion of future technologies with the eternal laws of nature.<br>About the harmony.',
          head1: 'Corporate identity graphic keys',
          h3_1: 'Human',
          h3_2: 'Line',
          h3_3: 'Gradient',
          h3_4: 'O - composition',
          p_1: 'A person is a key image in which all brand philosophies converge — art, science, technology evolution. He is the creator and crown of creation, the author of beautiful music and immortal works, the engine of high technology. Person is an ideal proportion, described by Leonardo da Vinci. The "Number of God" embedded in our concept is a symbol of the harmony of the universe. The image perfectly fits the concept of bionics about the search for absolute harmony between science, new technologies, art, spirituality, and culture. Graphically, it is presented technologically and futuristically, it is convenient to lay it out according to the directions of the festival and interpret it for different tasks, for example: a person is a DJ on a poster, this is a street art painting by an artist on the streets of Cyprus, inspired by ancient art and a series of illustrations, the main motive of which is also a person — the one who creates music and the one who who listens to it is a participant of the festival and our guest.',
          p_2: 'The visual metaphor of neural networks and neural connections in the brain, quantum and sea waves ...<br>Graphically, the element follows from the style of the logo woven from a single continuous line — such a visual solution is very convenient to work with, supports the overall concept.',
          p_3: 'It is not for nothing that the magenta-cyanide scheme of light is so often used in film production. This very harmonious gradient sets a cosmically futuristic atmosphere and looks advantageous both on digital media and offline.',
          p_4: 'The circle, as well as all existing symbols and layout systems that fit into it. An ideal harmonious figure - it is quickly read, it is easy to work with it, come up with plots for it and recognizably fit almost any graphics.',
        },
      },
    };
  },
  props: ['activeLang'],
};
</script>

<style scoped></style>
